import React from 'react';
import * as styles from './Shell.module.scss';

import { Footer } from 'patterns/Footer';
import { Header } from 'patterns/Header';
import { SEO } from 'patterns/SEO';

function Shell({
    children,
    pageDescription,
    pageSocialImage,
    pageTitle,
    pathname,
}) {
    const data = {
        header: {
            navLinks: [
                {
                    nav_cta: false,
                    nav_link_text: {
                        text: 'Business',
                    },
                    nav_link_url: {
                        link_type: 'Document',
                        uid: 'business',
                        url: '/business',
                    },
                },
                {
                    nav_cta: false,
                    nav_link_text: {
                        text: 'Health',
                    },
                    nav_link_url: {
                        link_type: 'Document',
                        uid: 'health',
                        url: '/health',
                    },
                },
            ],
            socialLinks: [
                {
                    social_link_type: {
                        text: 'Twitter',
                    },
                    social_link_url: {
                        link_type: 'Web',
                        url: 'https://twitter.com/robcsimps',
                    },
                },
                {
                    social_link_type: {
                        text: 'Instagram',
                    },
                    social_link_url: {
                        link_type: 'Web',
                        url: 'https://www.instagram.com/robcsimps',
                    },
                },
            ],
        },
        footer: {
            ctaButtonText: 'Join the newsletter',
            ctaTitle: 'No spam, just great content',
            ctaText: 'Insights into my life as a freelancer',
            ctaUrl: {
                link_type: 'Document',
                uid: 'newsletter',
            },
            navLinks: [
                {
                    nav_type: 'Primary',
                    nav_link_text: {
                        text: 'Privacy',
                    },
                    nav_link_url: {
                        link_type: 'Document',
                        type: 'content_page',
                        uid: 'privacy',
                        url: '/privacy',
                    },
                },
            ],
            socialLinks: [
                {
                    social_link_type: {
                        text: 'Twitter',
                    },
                    social_link_url: {
                        link_type: 'Web',
                        url: 'https://twitter.com/robcsimps',
                    },
                },
                {
                    social_link_type: {
                        text: 'Instagram',
                    },
                    social_link_url: {
                        link_type: 'Web',
                        url: 'https://www.instagram.com/robcsimps',
                    },
                },
            ],
        },
    };

    return (
        <div className={styles['shell']}>
            <SEO
                pageDescription={pageDescription}
                pageSocialImage={pageSocialImage}
                pageTitle={pageTitle}
                pathname={pathname}
            />
            <Header navLinks={data.header.navLinks} socialLinks={data.header.socialLinks} />
            <main className={styles['shell']} id="main-content">
                {children}
            </main>
            <Footer
                ctaButtonText={data.footer.ctaButtonText}
                ctaTitle={data.footer.ctaTitle}
                ctaText={data.footer.ctaText}
                ctaUrl={data.footer.ctaUrl}
                navLinks={data.footer.navLinks}
                pathname={pathname}
                socialLinks={data.footer.socialLinks}
            />
        </div>
    );
}

export { Shell };
